const router = async (el) => {
  let rout = 'base.js'
  const page = el || document.querySelector('.content-main')
  // Dynamically Load Modules
  switch (true) {

    // Home Page
    case page.classList.contains('entry-home'):
      // Import the module
      rout = 'home.js'

      break
    case page.classList.contains('entry-ourPeople'):
      rout = 'people.js'

      break

    case page.classList.contains('entry-service'):
    case page.classList.contains('entry-ourProjects'):
      rout = 'ourProjects.js'
      break

    case page.classList.contains('entry-project'):
      rout = 'project.js'
      break

    case page.classList.contains('tag-articleTags'):
    case page.classList.contains('entry-discover'):
    case page.classList.contains('entry-jobOpenings'):
      rout = 'articles.js'
      break

    case page.classList.contains('entry-article'):
      rout = 'article.js'
      break
  }

  const pageModule = await import('./routes/' + rout)
  const pageClass = new pageModule.default(page)
  pageClass.init()

  return pageClass
}

export default router
