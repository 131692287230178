import { gsap, ScrollTrigger } from 'gsap/all'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'

gsap.registerPlugin(ScrollTrigger)

class Nav {
  constructor(menu){
    this.menu = menu
    this.button = menu.querySelector('.menu-button')
    this.overlay = menu.querySelector('.nav-overlay')
    this.subItems = menu.querySelectorAll('.with-submenu')
    this.sticky = false
    this.open = false
  }

  init = () => {
    this.button.addEventListener('click', this.buttonHandler)
    this.overlay.addEventListener('click', this.overlayHandler)
    this.menuHide()
    this.subItems.forEach(i => i.addEventListener('click', this.subClickHandler))
    window.addEventListener('resize', this.resizeHandler)
  }

  reInit = () => {
    this.subItems = this.menu.querySelectorAll('.with-submenu')
    this.subItems.forEach(i => i.addEventListener('click', this.subClickHandler))
  }

  menuHide = () => {
    ScrollTrigger.create({
      trigger: document.body,
      start: '128px top',
      end: 99999,
      onUpdate: ({direction, isActive}) => {
        if(!this.open){
          if(direction == 1){
            this.menu.classList.add('-translate-y-full')
          }else{
            this.menu.classList.remove('-translate-y-full')
          }

          if(isActive){
            this.sticky = true
            this.menu.classList.add('small')
          }else{
            this.sticky = false
            this.menu.classList.remove('small')
          }
          
        }
      }
    })
  }

  buttonHandler = () => {
    this.menu.classList.toggle('open')
    if(this.menu.classList.contains('open')) {
      disablePageScroll()
      this.open = true
    }else{
      enablePageScroll()
      this.open = false
    }
  }
  overlayHandler = () => {
    this.menu.classList.remove('open')
    enablePageScroll()
    this.open = false
  }
  subClickHandler = e => {
    e.preventDefault()
    this.subItems.forEach(si => si.nextElementSibling.classList.remove('active'))
    e.currentTarget.nextElementSibling.classList.toggle('active')
  }

  resizeHandler = () => {
    if(window.matchMedia('(min-height: 1024px)')){
      this.overlayHandler()
      this.subItems.forEach(si => si.nextElementSibling.classList.remove('active'))
    }
  }
}

export default Nav