import { gsap, DrawSVGPlugin,  ScrollToPlugin } from 'gsap/all'

gsap.registerPlugin(DrawSVGPlugin)
gsap.registerPlugin(ScrollToPlugin)
// gsap.registerPlugin(GSDevTools)

const duration = 0.6
class Preloader {
  constructor(){
    this.el = document.querySelector('#preloader')
    this.lines = this.el.querySelectorAll('path')
    this.inner = this.el.querySelectorAll('.inner-preloader')
    this.anim = false
  }
  
  open = (callback = () => {}) => {
    gsap.fromTo(this.lines, {
      drawSVG: "0% 100%"
    }, {
      duration: 0.6,
      stagger: 0.06,
      ease: 'linear',
      drawSVG: "100% 100%",
      overwrite: true,
      // onUpdate: function(){
      //   if(this.progress() > 0.65 && !switcher) {
      //     callback()
      //     switcher = true
      //   }
      // },
      onStart: () => {
        this.anim = true
      },
      onComplete: () => {
        this.anim = false
        callback()
        this.el.hidden = true
      }
    })

    // GSDevTools.create({animation: tween, css: {zIndex: 1000}})
  }

  close = (callback = () => {}) => {
    // GSDevTools.create({animation: tween, css: {zIndex: 1000}})
    gsap.fromTo(this.lines, {
      drawSVG: "0% 0%"
    }, {
      duration: 0.3,
      stagger: 0.03,
      ease: 'linear',
      drawSVG: "0% 100%",
      overwrite: true,
      onStart: () => {
        this.el.hidden = false
        this.anim = true
      },
      onComplete: () => {
        this.anim = false
        callback()
      }
    })
  }
}

export default  Preloader